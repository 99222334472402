import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Heading,
  Container,
  HStack,
  Image,
  Box,
  Text,
  useColorModeValue,
  Wrap,
  WrapItem,
  IconButton,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";
import { FaFileAlt } from "react-icons/fa";

// import { PayloadContext } from "../utils/firebase";

// const baseUrl = 'https://storage.googleapis.com/composeart-f9a7a.appspot.com/demo/'

// const bases = ['grims', 'sfsc'];
// const bases = ['grims', 'sfsc'];
// const baseOptions: any = {
//   grims: `grims/grim`,
//   sfsc: `sfsc/sfsc`,
// };
// const featureOptions: any = {
//   grims: {
//     background: {
//       desert: `grims/backgrounds/desert`,
//       space: `grims/backgrounds/space`,
//       mountains: `grims/backgrounds/eth-denver-mountains`,
//     },
//     base: {
//       bone: `grims/texture/bone`,
//       diamond: `grims/texture/diamond-pattern`,
//       flower: `grims/texture/flower-pattern`,
//     },
//     mouth: {
//       gold: `grims/mouth/gold`,
//       frozen: `grims/mouth/eth-denver-snow-beard`,
//       rose: `grims/mouth/rose`,
//       smile: `grims/mouth/smile`,
//       vampire: `grims/mouth/vampire`,
//     },
//     eyes: {
//       coins: `grims/eyes/coins`,
//       goggles: `grims/eyes/eth-denver-goggles`,
//       mask: `grims/eyes/mask`,
//       patch: `grims/eyes/patch`,
//       ruby: `grims/eyes/ruby`,
//     },
//     head: {
//       cat: `grims/head/cat`,
//       cap: `grims/head/eth-denver-cap`,
//       crack: `grims/head/crack`,
//       mohawk: `grims/head/mohawk`,
//       tophat: `grims/head/tophat`,
//       bronzehorn: `grims/head/eth-denver-bronze-horn`,
//       silverhorn: `grims/head/eth-denver-silver-horn`,
//       goldhorn: `grims/head/eth-denver-winner-horn`,
//     },
//   },
//   sfsc: {
//     background: {
//       blue: `sfsc/background/blue`,
//       city: `sfsc/background/city`,
//       club: `sfsc/background/club`,
//       coins: `sfsc/background/coins`,
//       computer: `sfsc/background/computer`,
//       desert: `sfsc/background/desert`,
//       space: `sfsc/background/space`,
//       vaporwave: `sfsc/background/vaporwave`,
//       yellow: `sfsc/background/yellow`,
//     },
//     base: {
//       green: `sfsc/sfsc`,
//       alien: `sfsc/skin/alien`,
//       black: `sfsc/skin/black`,
//       cow: `sfsc/skin/cow`,
//       gold: `sfsc/skin/gold`,
//       red: `sfsc/skin/red`,
//       tattoo: `sfsc/skin/tattoo`,
//       trippy: `sfsc/skin/trippy`,
//       white: `sfsc/skin/white`,
//     },
//     mouth: {
//       cigar: `sfsc/mouth/cigar`,
//       clown: `sfsc/mouth/clown`,
//       gold: `sfsc/mouth/gold`,
//       normal: `sfsc/mouth/normal`,
//       open: `sfsc/mouth/open`,
//       rose: `sfsc/mouth/rose`,
//       smile: `sfsc/mouth/smile`,
//       smirk: `sfsc/mouth/smirk`,
//       vampire: `sfsc/mouth/vampire`,
//     },
//     eyes: {
//       bitcoinlazer: `sfsc/eyes/bitcoinlazer`,
//       coins: `sfsc/eyes/coins`,
//       evil: `sfsc/eyes/evil`,
//       mask: `sfsc/eyes/mask`,
//       nervous: `sfsc/eyes/nervous`,
//       normal: `sfsc/eyes/normal`,
//       patch: `sfsc/eyes/patch`,
//       ruby: `sfsc/eyes/ruby`,
//       sunglasses: `sfsc/eyes/sunglasses`,
//     },
//     clothing: {
//       blueshirt: `sfsc/clothing/blueshirt`,
//       cowboy: `sfsc/clothing/cowboy`,
//       denim: `sfsc/clothing/denim`,
//       flmtee: `sfsc/clothing/flmtee`,
//       poppedcollar: `sfsc/clothing/poppedcollar`,
//       sleeveless: `sfsc/clothing/sleeveless`,
//       spaceoveralls: `sfsc/clothing/spaceoveralls`,
//       suit: `sfsc/clothing/suit`,
//       tuxedo: `sfsc/clothing/tuxedo`,
//     },
//     head: {
//       captain: `sfsc/head/captain`,
//       cat: `sfsc/head/cat`,
//       crack: `sfsc/head/crack`,
//       headphones: `sfsc/head/headphones`,
//       mohawk: `sfsc/head/mohawk`,
//       redcap: `sfsc/head/redcap`,
//       spacehelmet: `sfsc/head/spacehelmet`,
//       tophat: `sfsc/head/tophat`,
//       trump: `sfsc/head/trump`,
//     },
//     accessory: {
//       bandana: `sfsc/accessories/bandana`,
//       bat: `sfsc/accessories/bat`,
//       champagne: `sfsc/accessories/champagne`,
//       fingerguns: `sfsc/accessories/fingerguns`,
//       glowsticks: `sfsc/accessories/glowsticks`,
//       gun: `sfsc/accessories/gun`,
//       headphones: `sfsc/accessories/headphones`,
//       okay: `sfsc/accessories/okay`,
//       pocketwatch: `sfsc/accessories/pocketwatch`,
//     },
//   },
// }

import pushLight from '../images/push.png';
import pushDark from '../images/push-dark.png';

export const Home = (props: RouteComponentProps) => {
  const lineColor = useColorModeValue('gray.500', 'white.500');
  const opacityColor = useColorModeValue('gray.800', 'white');
  const composeLogo = useColorModeValue(pushLight, pushDark);

  useEffect(() => {
    document.title = 'The Risk Free Way to Use Your NFT';
  }, []);

  return (
    <Container maxW='container.lg' centerContent>
      <Text fontSize='3xl' textAlign={"center"}>
       Secure NFT <br/>Utility Network
      </Text>
      <Text fontSize='md' marginTop={2}>
        The Risk Free Way to Use Your NFT
      </Text>
      <Image
        marginTop={10}
        marginBottom={4}
        boxSize="300px"
        src={composeLogo}
      />
      <IconButton
        marginRight={"70px"}
        size="6xl"
        fontSize="6xl"
        variant="ghost"
        color="current"
        onClick={() => window.open("https://docsend.com/view/j4f9h4bwrn8wgq6t", "_blank")}
        icon={<FaFileAlt />}
        aria-label={`Current`}
        opacity={1}
      />
    </Container>
  );
};

// export const Home = (props: RouteComponentProps) => {
//   const lineColor = useColorModeValue('gray.500', 'white.500');
//   const opacityColor = useColorModeValue('gray.800', 'white');

//   const [current, setCurrent]: any = useState(false);
//   const [base, setBase]: any = useState(bases[0]);
//   const [category, setCategory]: any = useState(0);
//   const [features, setFeatures]: any = useState([{
//     img: baseOptions[bases[0]],
//     label: bases[0],
//     level: 1,
//   }]);

//   const { account } = useContext(PayloadContext);

//   useEffect(() => {
//     document.title = 'Composable PFP NFTs';
//   }, []);

//   const shufflePersona = () => {
//     const usableFeatures = featureOptions[base];

//     const build: any = [];
//     let level = 0;

//     _.forEach(usableFeatures, (options: any, key: any) => {
//       const list = _.map(options, (f: any, k: any) => {
//         return {
//           img: f,
//           label: k,
//         };
//       });
//       const item = _.sample(key === 'base' ? list : [...list, {}]);

//       if (!_.isEmpty(item)) {
//         build.push({
//           ...item,
//           category: key,
//           level,
//         });
//       }

//       level++;
//     });

//     setFeatures(build);
//   }

//   const categories = _.map(featureOptions[base], (v, k) => k);

//   const categoryFeatures = _.map(featureOptions[base][categories[category]], (f, i) => {
//     return {
//       label: i,
//       img: f,
//       level: category,
//       category: categories[category],
//     }
//   });

//   const orderedFeatures = _.orderBy(features, ['level'], ['asc']);

//   return (
//     <Container maxW='container.lg' centerContent>
//       <Center
//         borderRadius="300px"
//         borderColor={lineColor}
//         borderWidth={2}
//         boxSize="320px"
//         alignItems="center"
//         justifyContent="center"
//       >
//         {orderedFeatures.map((f: any, i: any) => {
//           return (
//             <Image
//               key={i}
//               boxSize="300px"
//               borderRadius="300px"
//               src={`${baseUrl}${f.img}.png`}
//               style={{
//                 position: 'absolute',
//               }}
//             />
//           );
//         })}
//       </Center>
//       <HStack width="300px" justify="space-between">
//         <Tooltip label={'current'}>
//           <IconButton
//             size="md"
//             fontSize="lg"
//             variant="ghost"
//             color="current"
//             onClick={() => setCurrent(!current)}
//             icon={<FaUserCircle />}
//             aria-label={`Current`}
//             opacity={current ? 1 : 0.5}
//           />
//         </Tooltip>
//         <Tooltip label={'shuffle'}>
//           <IconButton
//             size="md"
//             fontSize="lg"
//             variant="ghost"
//             color="current"
//             opacity={1}
//             onClick={() => {
//               shufflePersona()
//             }}
//             icon={<ImShuffle />}
//             aria-label={`Shuffle`}
//           />
//         </Tooltip>
//       </HStack>
//       <HStack
//         width="100vw"
//         justify="center"
//         align="center"
//         spacing={8}
//         marginTop={2}
//         borderBottomWidth={1}
//         borderBottomColor={lineColor}
//         padding={4}
//       >
//         {bases.map((b: any) => {
//           return (
//             <Text
//               key={b}
//               onClick={() => {
//                 if (b !== base) {
//                   setBase(b);
//                   setFeatures([{
//                     img: baseOptions[b],
//                     category: b,
//                     level: 1,
//                   }]);
//                   setCategory(0);
//                 }
//               }}
//               opacity={b === base ? 1 : 0.5}
//               _hover={{
//                 cursor: 'pointer',
//                 textDecoration: 'underline'
//               }}
//             >
//               {b}
//             </Text>
//           );
//         })}
//       </HStack>
//       <HStack
//         width="100vw"
//         justify={{ base: current ? "center" : "normal", md: "center" }}
//         align="center"
//         spacing={8}
//         borderBottomWidth={1}
//         borderBottomColor={lineColor}
//         padding={4}
//         overflowX={'scroll'}
//       >
//         {!current && categories.map((c, i) => {
//           return (
//             <Text
//               key={i}
//               onClick={() => {
//                 setCategory(i);
//               }}
//               opacity={i === category ? 1 : 0.5}
//               _hover={{
//                 cursor: 'pointer',
//                 textDecoration: 'underline'
//               }}
//             >
//               {c}
//             </Text>
//           );
//         })}
//         {current && (
//           <Text>
//             current
//           </Text>
//         )}
//       </HStack>
//       <Wrap marginTop={8} justify='center' spacing={4}>
//         {!current && categoryFeatures.map((f: any, i: any) => {
//           const wearing = features.find((feat: any) => feat.img === f.img);

//           return (
//             <WrapItem key={i} margin={2}>
//               <Tooltip label={f.label}>
//                 <Box
//                   position="relative"
//                   borderRadius="80px"
//                   borderColor={wearing ? opacityColor : lineColor}
//                   borderWidth={1}
//                   onClick={() => {
//                     if (wearing && f.level !== 1) {
//                       setFeatures(_.filter(features, (feat: any) => feat.img !== f.img));
//                     }

//                     if (!wearing) {
//                       const newFeatures = _.filter(features, (feat: any) => feat.level !== f.level);
//                       newFeatures.push(f);
//                       setFeatures(newFeatures);
//                     }
//                   }}
//                   _hover={{
//                     cursor: "pointer",
//                     borderColor: opacityColor,
//                   }}
//                 >
//                   <Image
//                     boxSize="80px"
//                     borderRadius="80px"
//                     src={`${baseUrl}${f.img}-small.png`}
//                   />
//                   {wearing && (
//                     <Box position="absolute" right="0px" bottom="0px">
//                       <FaCheckCircle fontSize={18} />
//                     </Box>
//                   )}
//                 </Box>
//               </Tooltip>
//             </WrapItem>
//           );
//         })}
//         {current && features.map((f: any, i: any) => {
//           return (
//             <WrapItem key={i} margin={2}>
//               <Tooltip label={`${f.label} ${f.category}`}>
//                 <Box
//                   position="relative"
//                   borderRadius="80px"
//                   borderColor={opacityColor}
//                   borderWidth={1}
//                   onClick={() => {
//                     if (f.level !== 1) {
//                       setFeatures(_.filter(features, (feat: any) => feat.img !== f.img));
//                     }
//                   }}
//                   _hover={{
//                     cursor: "pointer",
//                     borderColor: opacityColor,
//                   }}
//                 >
//                   <Image
//                     boxSize="80px"
//                     borderRadius="80px"
//                     src={`${baseUrl}${f.img}-prop.png`}
//                   />
//                   <Box position="absolute" right="0px" bottom="0px">
//                     <FaCheckCircle fontSize={18} />
//                   </Box>
//                 </Box>
//               </Tooltip>
//             </WrapItem>
//           );
//         })}
//       </Wrap>
//     </Container>
//   );
// };
