import * as React from "react"
import {
  Container,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";

export const NotFound = (props: RouteComponentProps) => {
  return (
    <Container maxW='container.xl' centerContent>

    </Container>
  );
};
