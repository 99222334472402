import { createContext, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  where,
  onSnapshot,
} from 'firebase/firestore';
import _ from 'lodash';

const projectId = 'composeart-f9a7a';

const app = initializeApp({
  apiKey: 'AIzaSyBK-EdRy8HJWm9LiMeLPr-q_kBTfSfTcVY',
  authDomain: `${projectId}.firebaseapp.com`,
  projectId,
  databaseURL: `https://${projectId}.firebaseio.com`,
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

interface PayloadTypes {
  account: string | null | undefined;
  chain: string | null | undefined;
  block: string | null | undefined;
}

const defaultPayload: PayloadTypes = {
  account: '',
  chain: '',
  block: '',
};

export const PayloadContext = createContext(defaultPayload);

function delay(delay: any) {
  return new Promise (function(fulfill) {
    setTimeout(fulfill, delay);
  });
};

export const chainQuery = doc(db, 'chains', 'goerli');


