import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { Router, globalHistory } from "@reach/router";
import { useEthers, ChainId } from "@usedapp/core";
import { QueryParamProvider } from 'use-query-params';
import { useDocument } from 'react-firebase-hooks/firestore';
import "@fontsource/fira-mono";
import "@fontsource/rock-salt";

import { NotFound } from "./screens/NotFound";
import { Home } from "./screens/Home";

import { Footer } from "./components/Footer";
import { Subheader } from "./components/Subheader";
import { Header } from "./components/Header";

import {
  PayloadContext,
  chainQuery,
} from "./utils/firebase";

const theme = extendTheme({
  initialColorMode: 'dark',
  // useSystemColorMode: true,
  fonts: {
    heading: 'Fira Mono',
    body: 'Fira Mono',
  },
  styles: {
    global: {
      '.identicon': {
        borderRadius: '50%',
      },
      '.tournament': {
        justifyContent: 'center',
      }
    },
  },
});

const ScrollToTop = ({ children, location }: any) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

const Nav = (props: any) => {
  const { account, chainId } = useEthers();
  const chain = chainId && ChainId[chainId];
  const [chainDoc, chainLoading, chainError] = useDocument(chainQuery);
  const remoteChain = chainDoc?.data() || {};

  return (
    <ChakraProvider theme={theme}>
      <PayloadContext.Provider
        value={{
          account: account ? account.toLowerCase() : null,
          chain,
          block: remoteChain.blockNumber || '',
        }}
      >
        <Header />
        {/* <Subheader /> */}
        {props.children}
        {/* <Footer /> */}
      </PayloadContext.Provider>
    </ChakraProvider>
  );
};

export const App = () => (
  <Router primary={false}>
    <QueryParamProvider {...{ path: '/' }} reachHistory={globalHistory}>
      <ScrollToTop path="/">
        <Nav path="/">
          <NotFound default />
          <Home path="/" />
        </Nav>
      </ScrollToTop>
    </QueryParamProvider>
  </Router>
);
